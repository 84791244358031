import React, { useEffect, useState } from "react";
import FeaturedProducts from "../FeaturedProducts/FeaturedProducts";
import "./Home.scss";

import sample from "../../img/hero.mp4";
import one from "../../img/one.webm";
import two from "../../img/two.webm";
import three from "../../img/three.webm";
import backImg from "../../img/banner.jpg";
import { Link } from "react-router-dom";
import Newsletter from "../../components/Newsletter/Newsletter";
import Popupdv from "../../components/popup/Popup";
import SlideShow from "../../components/slideshow/SlideShow";
import Social from "../../components/Social/Social";
import Modal from "../../components/modal/Modal";


const Home = () => {


  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="max-width">
      <div className="home">
        <div className="hero_wrappe">
          <div className="__hero">
            <SlideShow />
          </div>

          <div className="heading">
            <p>
              CHOCODATE combines the heritage and modern cultures creating a
              guilt-free indulgence pleasing to every palate across the globe.
            </p>
          </div>
        </div>
        <div className="heading-two">
          <h1 style={{ fontSize: "35px" }}>
            "Perfect blend between the{" "}
            <em
              style={{
                color: "#fad800",
                margin: "0px 10px",
                fontStyle: "normal",
              }}
            >
              Arabian heritage <br /> and the passion
            </em>
            for savoury treats"
          </h1>
        </div>

        <FeaturedProducts type="featured" />
        <div className="ad_about">
          <strong style={{ color: "#fad800" }}>
            Arabian Delights by Chocodate is an enterprise born out of a dream
            to create unique and lasting chocolate delicacies. Our mission has
            always been to ensure the perfect blend between the Arabian heritage
            and the passion for savoury treats.
          </strong>
          <br />
          <br />

          <p>
            Fusing an exclusive trio of the most sumptuous Arabian dates, crisp
            almonds and Belgian chocolate, the Chocodate experience gives you
            goodness with every bite. Today, Chocodate’s exceptional origins
            have launched it from hidden gem, homegrown in Dubai, to one of the
            most popular and globally recognized specialty dates treats in the
            world. Nowhere else in the world will you find such a great variety
            of new and above all delicious flavours and combinations.
          </p>
        </div>

        <div className="in_banner_con">
          <div className="in_banner __wrap ">
            <div className="text_bannr">
              <h2>
                Gluten-free, GMO-free, low in sodium, and low in fat, the
                Chocodate miniature chocolate treats are the perfect healthy
                snack for you to munch on & are free of additives and harmful
                preservatives.
              </h2>
            </div>
            <div className="img_bannr">
              <img src={require("../../img/N IMG.png")} alt="" />
            </div>
          </div>
        </div>

        <div className="product_info_section_one">
          <div className="img_wrap">
            <img src={require("../../img/chocodate1.png")} />
          </div>
          <div className="text">
            <h1>Mouth-Watering Delicacy</h1>
            <div className="strong">
              <strong>
                At Chocodate, we’ve created a luxurious experience with a
                wholesome approach to health and wellness
              </strong>
            </div>
            <div>
              <p>
                that will give you a natural boost of goodness, alongside the
                fullness of our flavours, to help you live your best life. All
                our products are free of additives and harmful preservatives. We
                believe in creating a delicious offering for those demanding
                lifestyles and we are focused on bringing a world of flavours to
                you.
              </p>
            </div>
          </div>
        </div>

        <Newsletter />
      </div>
    </div>
  );
};

export default Home;
