import React, { useCallback } from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const stripePromise = loadStripe(
  "pk_live_51NZq6pLFX6mtMcpcmmo6KvEu2tURioX0Ip3uTWUs0eZGPLe3qVUpyXcR3H27pfrkt7PQ1izb6sK5LTW2FmzEF4Sp00jGDUWGNH"
  // "pk_test_51NdnknKd2qy2P3URf4qvbTs4S4JIjCuVu2ki2c3Cw7E9MRSrWpNbQWT3xvke6OHppDgp4LqrzV5p94vLJslxcZGV00SqX0bJLr"
);



const CheckoutForm = ({ productsData }) => {
console.log("productsData", productsData.price);
  const products = [
    {
      title: "Notions Group",
      price: productsData.price,
      // price: 3,
      name: "Customer",
      quantity: productsData.quantity,
      brand: "arabiandelights", // ! this brand for payment gateway return url select
    },
  ];

  // console.log(products);

  const fetchClientSecret = useCallback(async () => {
    try {
      const body = {
        products,
      };

      const headers = {
        "Content-Type": "application/json",
      };

      // Create a Checkout Session
      const response = await axios.post("/create-checkout-session", body, {
        headers: headers,
      });

      return response.data.clientSecret;
    } catch (error) {
      console.error("Error fetching client secret:", error);
      throw error; // Optionally re-throw to handle higher up
    }
  }, [products]);

  const options = { fetchClientSecret };

  return (
    <div>
      <div id="checkout">
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </div>
  );
};

export default CheckoutForm;
